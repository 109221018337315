/*******dasboard center********/


.dashboard .main-dashboard  .box-top-title{
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 0.3rem 0;
  font-weight: 600;
  opacity: .8;
}

.menu-dropdown-row{
   background: #fff;
   border-radius: 0.3rem;
   box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
   min-width: 110px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   z-index: 99;
   overflow: hidden;
   padding: 0.2rem 0;
}
.menu-dropdown-row span{
  border-bottom:  1px solid var(--border-color);
  display: flex;
  padding: 0.5rem;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.menu-dropdown-row span:hover{
 background: #f8f9fa;
}
.menu-dropdown-row span.disabled{
  opacity: .4;
  pointer-events: none;
  cursor: initial;
}

.menu-dropdown-row span svg{
  opacity: .8;
}
.menu-dropdown-row span:last-child{
  border-bottom:0;
}

.__meunu{
  position: absolute;
  height:  100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: none;

}


.loan-container .main-section .count-cards{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.loan-container .main-section .count-cards .item{
  display: flex;
  background: #F3F3F3;
  border-radius: 0.3rem;
  color: #616161;
  flex-direction: column;
  padding: 0.5rem;
  align-items: center;
  margin-right: 1rem;
  min-width: 100px;
  margin-bottom: 0.6rem;
}

.loan-container .main-section .count-cards .item .label{
   font-size: 1.2rem;
}
.loan-container .main-section .count-cards .item .value{
  font-weight: 600;
}

@media screen and (max-width:1024px){
    .loan-container .main-section .count-cards .item{
       min-width: 180px;
       text-align: center;
    }

}


@media screen and (max-width:600px){
    .loan-container .main-section .count-cards .item{
      width: 48%;
      margin-right:  2%;
      min-width: auto;
    }
}

@media screen and (max-width:500px){
    .loan-container .main-section .count-cards .item{
      width: 48%;
      margin-right:  2%;
      min-width: auto;
    }


}




 
