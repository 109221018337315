

.dashboard .main-dashboard .center .stat_page_1 .right-side .chart-c .box-top-title{
  padding: 0.5rem 1rem;
  display: flex;
}
.dashboard .main-dashboard .box-top-title{
  display: flex;
  justify-content:space-between;

}

.dashboard .main-dashboard .box-top-title .r{
  font-weight: 400;
  display: flex;
 
}
.dashboard .main-dashboard .box-top-title .r label{
   margin-left: 0.1rem;
   min-width: 80px;
   display: table;
   padding-left: 0.5rem;
   border-left: 1px solid var(--border-color);
}
.dashboard .main-dashboard .box-top-title .r label:first-child{
   border-left:0;
}

.dashboard .main-dashboard  .stat_page_3 .loans-status .list .item .r{
  display: flex;
}
.dashboard .main-dashboard  .stat_page_3 .loans-status .list .item .r span{
   margin-left: 0.1rem;
   min-width: 80px;
   display: table;
   padding-left: 0.5rem;
   font-size: 0.9rem;
}
.dashboard .main-dashboard  .stat_page_3 .loans-status .list .item .r span label{
  display: none;
}





@media (max-width: 700px){
   .dashboard .main-dashboard .box-top-title .r{
      display: none;
    }
    .dashboard .main-dashboard  .stat_page_3 .loans-status .list .item .r{
      flex-direction: column;
    }
   .dashboard .main-dashboard  .stat_page_3 .loans-status .list .item .r span{
     width: 100%;
   }
   .dashboard .main-dashboard  .stat_page_3 .loans-status .list .item .r span label{
      opacity: .7;
   }
   .dashboard .main-dashboard  .stat_page_3 .loans-status .list .item .r span label{
      display:inline;
    }



}
