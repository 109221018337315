/*@import "../css/main.css";*/

/******* Login *********/
.login-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /*background: #DEE4F0;*/
  flex-direction: column;
}
.login-container .footer{
  margin-top: 3rem;
  font-size: 0.8rem;
  opacity: 0.5;
}

.login-container .center-div{
	width: 600px;
	display: flex;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 0.6rem;
  margin-top: 3rem;

}

.login-container .login-form{
	width: 50%;
	height: 100%;
  padding: 2rem;
}
.login-container .login-form h2{
	margin-bottom: 2.2rem;
  font-weight: 500;
  font-size: 1.5rem;
  opacity: .8;
}
.login-container .login-form .div-input{
  width: 100%;
  height: 37px;
  margin-bottom: 0.6rem;
  display: flex;
  align-items: center;
  position: relative;

}

.login-container .login-form .div-input input{
	height: 100%;
	flex: 1;
  border: 0;
  outline: none;
  transform: translateY(-0.1rem);
  background: #FCFCFC;
  padding-left: 0.4rem;
  color: #4A4A4A;
  border: 1px solid #ebebeb;
  width: 100%;
  border-radius: 0.3rem;
  padding-left: 2rem;
}


.login-container .login-form .div-input input::placeholder{
  font-size: 0.81rem;
}
.login-container .login-form .div-input input:focus{
  border:2px solid #1757DB;
  transition: 0.1s;
}

.login-container .login-form .div-input svg{
  fill: #D9D9D9;
  width: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.login-container .forgot-password{
  margin: 1.4rem 0;
  text-align: right;
  color: #2360E4;
  font-size: 0.9rem;
  cursor: pointer;
}
.login-container .forgot-password:hover{
  opacity: .7;
}

.login-container button{
  
  width: 100%;
  background: #1757DB;
  color: #fff;
  padding: 0.4rem;
  border-radius: 0.4rem;
  cursor: pointer;
  height: 100%;

}

.login-container button:hover{
  opacity: .8;
}
.login-container button:active{
  opacity: 1;
}


.login-container  .div_btn{
   width: 100%;
   position: relative;
   height: 36px;
   margin-top: 1.5rem;
   margin-bottom: 6rem;
}


.login-container  .div_btn .loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 25px;
    height: 25px;
    border: 2px solid #ddd;
    border-top:  2px solid var(--main-color);
    border-left:  2px solid var(--main-color);
    border-radius: 50%;
    float: right;
    animation: btn_loader 0.5s linear infinite;
    opacity: 0;
    pointer-events: none;

}

@keyframes btn_loader{
  from{
         transform:translate(-50%,-50%) rotate(-360deg);
  }to{
        transform:translate(-50%,-50%) rotate(0deg);
  }
}

.login-container.loading  button{
    opacity: 0;
    pointer-events: none;

}
.login-container.loading  .div_btn .loader{
    opacity: 1;
}


 .login-container  .text-container{
  position: relative;
  width: 50%; 
  height: 460px;
  border-radius: 0.6rem;
  overflow: hidden;

 }
 .login-container  .text-container .bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 }
 .login-container  .text-container .top-content{
   position: absolute;
   padding: 2rem;
   height: 100%;
   background: #1757DB;
   background-image: url('./images/login-right-image.png');
   background-position: center;
   background-size: cover;
   width: 100%;

 }

 .login-container  .text-container .top-content span{
   font-size: 2rem;
   color: #fff;
 }

 .login-container  .text-container .copy {
   position: absolute;
   bottom: 1rem;
   right: 1rem;
   color: #fff;
}





.logo-c {
  display: flex;
  margin-bottom: 1.8rem;

}
.logo-c  h1{
 /* margin-left: 1rem;*/
    font-size: 1rem;
    text-align: right;
    opacity: 0.9;
}
.logo-c svg {
  opacity: .7;
}

@media screen and (max-width:1024px){
      .login-container{
         padding: 1rem;
      }
      .login-container .center-div{
        width: 325px;
     }
     .login-container .login-form{
         width:100%;
     }
      .login-container .text-container{
        display: none;
     } 
} 

@media screen and (max-width:300px){
  .login-container .center-div{
     width: 100%;
     height: 100%;
  }
  .login-container .login-form{
      width:100%;
  }
   .login-container .text-container{
     display: none;
  } 

  .login-container{
    padding: 0;
  }
} 


.btn-select-account{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    cursor: pointer;
     color:  #1757DB;
     text-decoration: underline;
}
.btn-select-account:hover{
  opacity: .7;

}








   
 
