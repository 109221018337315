.loan-container{
	width: 100%;
	height: 100%;
	background: #fff;
	border-radius: 0.5rem;
	min-height: 70vh;
	padding-bottom: 2rem;
}

.loan-container .top-title{
	width: 100%;
	padding: 1rem;
	border-bottom:1px solid var(--border-color);
	justify-content: space-between;
	display: flex;

}

.loan-container .top-title button{
	padding: 0.1rem;
	outline: none;
	background: var(--main-color);
	color: #fff;
	padding: 0.3rem 0.5rem;
	border-radius: 0.3rem;
}
.loan-container .top-title button:hover{
	opacity: .8;
}


.loan-container .top-options{
   display: flex;
   border-bottom:1px solid var(--border-color);
   align-items: center;

}
.loan-container .top-options span{
	display: table;
	height: 100%;
	padding: 0.4rem 1rem;
	cursor: pointer;
	transition: 0.1s ease;
	border-right:  1px solid var(--border-color);
}
.loan-container .top-options span:active{
	opacity: .7;
}
.loan-container .top-options span.active{
	background:#789DEA;
	color: #fff;

}

.loan-container .top-options span:not(.active):hover{
	color: #789DEA;
}

.loan-container .main-section{
  padding: 1rem;

}



.loan-container .main-section .input-container{
	 margin-top: 2rem;
     display: flex;
    flex-wrap: wrap;
    max-width: 900px;
}
.loan-container.edit-mode .main-section .input-container{
	display: none;
}

.loan-container .main-section .input-container .inputs{
	display: flex;
    flex-direction: column;
    width: 32%;
    margin-bottom: 1.5rem;
    margin-left: 1%;

}
.loan-container .main-section .input-container textarea{
   height: 80px;
}

.loan-container .main-section .table{
	width: 100%;
}


.loan-container .main-section > div.contract_model{

}
.loan-container .main-section > div.contract_model .section-div{
	width: 100%;
	border-bottom: 1px solid var(--border-color);
	padding: 1rem 0;
	display: flex;
	margin-bottom: 1rem;
	align-items: center;
}
.loan-container .main-section > div.contract_model .section-div span{
	opacity: .7;
}
.loan-container .main-section > div.contract_model .section-div button{
	border: 1px solid var(--main-color);
    color: var(--main-color);
    border-radius: 0.2rem;
    margin-left: 1rem;
    padding: 0.3rem 1.5rem;
}

.loan-container .main-section > div.contract_model .sending-inputs{
	display: flex;
	align-items: center;
	margin-bottom: 0.6rem;
}
.loan-container .main-section > div.contract_model .sending-inputs > span{
	opacity: .7;
	margin-right: 3rem;
	display: flex;
	min-width: 300px;
}

.loan-container .main-section > div.contract_model .sending-inputs .inputs-c{
	border: 1px solid var(--border-color);
	border-radius: 0.2rem;
	position: relative;
	display: flex;
	padding: 0.2rem;
	align-items: center;
	justify-content: space-between;
	min-width: 250px;
}
.loan-container .main-section > div.contract_model .sending-inputs .see-file{
	margin-left: 1rem;
	opacity: .5;
	cursor: pointer;
}
.loan-container .main-section > div.contract_model .sending-inputs .see-file:hover{
	opacity: .7;
}
.loan-container .main-section > div.contract_model .sending-inputs .inputs-c button{
	padding: 0.4rem 1rem;
	background: #F3F3F3;
	border: 0;
	margin-right: 0.4rem;
	color: #605D5D;
}
.loan-container .main-section > div.contract_model .sending-inputs .inputs-c input{
	position: absolute;
	top: 0;
	left: 0;
	cursor: pointer;
	z-index: 1;
	height: 100%;
	width: 100%;
	opacity: 0;
}
.loan-container .main-section > div.contract_model .sending-inputs .inputs-c span{
	padding: 0.5rem;
}
.loan-container .main-section > div.contract_model .sending-inputs .inputs-c input:hover ~ button{
	opacity: .7;
	cursor: pointer;
}

.loan-container .main-section > div.contract_model .section-div button:hover{
	opacity: .7;
}

.loan-container .btn-send {
	justify-content: flex-end;
	padding:0 2rem;
	display: flex;
}
.loan-container .message{
   position: absolute;
   bottom: 1.5rem;
   display: flex;
   padding: 0.5rem;
   border-radius: 0.3rem;
   background: #fff;
   box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
   left:  260px;
   border: 1px solid #FFA500;
   opacity: 0;
   pointer-events: none;
   transform: scale(0.8);
   transition: 0.2s;
}
.loan-container .message.show{
	transform: scale(1);
	opacity: 1;
}
.loan-container .message span{
	margin-left: 0.5rem;
	opacity: .7;
}

.loan-container .btn-send .div_btn{
   display: table;
}
.loan-container .btn-send  button{
	padding:0.5rem 1rem;
	color: #fff;
	background: #2360E4;
	border-radius: 0.3rem;
}
.loan-container.loading button{
	cursor: initial;
}
.loan-container.loading button span{
   pointer-events: none;
   opacity: 0;
}
.loan-container.loading .loader{
  opacity: 1;
}

.loan-container:not(.loading) .btn-send  button:hover{
	opacity: .7;
}

.btn-add-installment{
	display: flex;
	justify-content: flex-end;
}
.btn-add-installment .btn{
	background:#F8F8F8;
	color: #2360E4;
	display: flex;
	padding: 0.4rem;
	border:1px solid var(--border-color);
	border-bottom: 0;
	font-size: 0.9rem;
	cursor: pointer;
}
.btn-add-installment .btn:hover{
	opacity: .8;
}

@media screen and (max-width:1200px){
   .loan-container .main-section .input-container .inputs{
      width: 48%;
   }
   .loan-container .main-section > div.contract_model .sending-inputs {
   	margin-bottom: 1rem;
   	padding-right: 0;
   	flex-wrap: wrap;
   	justify-content: flex-end;
   }
   .loan-container .main-section > div.contract_model .sending-inputs span{
     	min-width: initial;
     	width: 100%;
     	display: flex;
   }
   .loan-container .main-section > div.contract_model .sending-inputs .inputs-c{
    min-width: 100%;
   }
   .loan-container .main-section > div.contract_model .sending-inputs .see-file{
   	margin-top: 0.5rem;
   }


}

@media screen and (max-width:700px){
   .loan-container .main-section .input-container .inputs{
      width: 100%;
   }
}

