
._container *{
  padding: 0;
  margin: 0;
 /* font-family: "Open Sans", sans-serif;*/
  font-family: Inter,system-ui,Avenir,Helvetica,Arial,sans-serif;
  scroll-behavior: smooth;
  color: #000;
  box-sizing: border-box;
}
._container{
    background: #fff;
}

._container *{
    align-items: center;
}

[role="status"]{
    color: #000 !important;
}

input,
select
{
  background: #fff;
}

._container ._menu{
  padding: 1rem 3rem;
  color: rgba(255, 255, 255, 0.87);
  z-index: 4;
}

._menu.float,
._container._white_menu ._menu
{
  background: #fff;
  border-bottom: 1px solid #ebebeb;
 
}
._container ._menu *{
  color: #fff;
}
._container ._menu.float{
  position: fixed;
  top: 0;
  left: 0;
}
._container._white_menu ._menu.float{
  position: relative;
}

._menu ._logo{
  margin-right: 3rem;
  font-size: 1.2rem;
}

._menu h1{
  font-weight: 600;
}
._menu h1 .short{
  display: none;
}
._menu.float h1 span,
._container._white_menu ._menu h1 span
{
  color: #000;
}
._menu ._left ._links li{
   margin: 0 0.6rem;
}
._menu ._left ._links li a{
   font-weight: 400;
}



._menu.float ._left ._links li a,
._container._white_menu ._menu li a
{
   color: #000 !important;
}


._container ._menu button._login a{
  color: #000;
}
4
._menu ._left ._links li:hover{
  opacity: .8;
  transition: 0.1s;
  cursor: pointer;
}
._menu.float ._left ._links li:hover a,
._container._white_menu ._menu ._left ._links li:hover a
{
  color: var(--main-color);
}

._menu ._left ._links .btn-close-menu{
  display: none;
}

._menu .burger{
  display: none;
}

._menu button{
   padding: 0.4rem 1rem;
   border-radius: 0.3rem;
   background: #fff;
   margin-right: 0.6rem;
   cursor: pointer;
}
._container ._menu button._login{
  background: transparent;
  border:1px solid rgba(255,255,255,0.5);
  display: none;
}
._menu.float button._login,
._container._white_menu ._menu button._login
{
   border: 1px solid rgba(0,0,0,0.2);
   color:rgba(0,0,0,0.8);
}
._container._white_menu ._menu button._register{
  border: 1px solid var(--main-color);
}

._container ._menu button._register{
   background: #fff;
   color: #467EF7;
   border:1px solid #fff;
}

._container ._menu.float button._register{
  background: #467EF7;
}

._container ._menu:not(.float) button._register:hover{
  background: transparent;
}
._container ._menu.float button._register:hover{
  opacity: .9;
}
._container ._menu button._register{
  color: var(--main-color);
}
._container ._menu.float button._register{
  color: #fff;
}
._container:not(._white_menu) ._menu button._register:hover{
  color: #fff;
}
._container ._menu button._register 

._container.login ._menu button._register{
   display: none;
}

._container.login ._menu button._login{
   display: block;
}

._menu button._login:hover{
   opacity: 0.7;
}


._home {
  background: blue;
  background: #0919D7;
  /*background-image: linear-gradient(135deg, #0919D7 0%, #4372D9 100%);*/
  padding-top:50px;
  width: 100%;
  height: 100%;
  background: url(../../../assets/images/home-bg.svg);
  background-position: center;
  background-size: cover;
  position: relative;
}

._home:after{
  position: absolute;
  top: 0;
  left: 0;

}

._home-content{
  display: flex;
  width: 100%;
  height: 100vh;
   overflow: hidden;
   min-height: 420px;
}

._home-content ._left-home{
   color: #fff;
   padding: 2rem;
}

._home-content ._left-home *{
  color: #fff;
}

._home-content ._left-home {
  padding-left: 100px;
  width: 50%;

}

._home-content ._left-home ._btns{
  margin-top: 1.5rem;
}

._home-content ._left-home button{
   padding: 0.5rem 1.7rem;
   border:1px solid #fff;
   border-radius: 0.3rem;
   margin-right: 0.4rem;
}
._home-content ._left-home button._demo{
   color: #fff;
   background: transparent;

}

._home-content ._left-home button._test{
  background: #fff;
 
}

._home-content ._left-home button._test:hover{
    background: transparent;
    transition: 0.1s;
}
._home-content ._left-home button._test{
   color: var(--main-color);
}
._home-content ._left-home button._test:hover{
  color: #fff;
}

._home-content ._left-home button._demo:hover{
   opacity: .8;
}

._home-content ._left-home h3{
   font-weight: 700;
   font-size: 2rem;
   margin-bottom: 1.2rem;
   max-width: 460px;

}

._home-content ._left-home  span{
  display: flex;
  max-width: 460px;
}


._home-content ._right-home {
   flex: 1;
   position: relative;
}
._home-content ._right-home img{
   width: 120%;
   max-width: 120%;
}

.about-short{
  display: flex;
  padding:3rem 1rem;
  align-items: initial;
  margin-top: 3rem;
  overflow: hidden;
  padding-left: 4rem;
}

.about-short > div{
 
}

.about-short .left{
  display: flex;
  flex-direction: column;
  align-items: initial;
  width: 100%;
  margin-right: 0rem;
}

.about-short .right{
  width: 130%;
  position: relative;
}
.about-short .right:after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    top: 0;
    right: 0;
    transform: scale(1.2);
    background: url(../../../assets/images/short-about-bg.svg) left top / contain no-repeat;
    right: -20%;
    top: 5%;
}
.about-short .right img{
    position: relative;
    z-index: 1;
}

.about-short .title{
   font-size: 2rem;
   font-weight: 600;
   display: table;
   position: relative;
   margin-bottom: 2rem;
}

.about-short h2{
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 1.05rem;

}
.about-short .title label{
  color: var(--main-color);
}



.about-short .title:after{
  position: absolute;
  bottom: -0.6rem;
  left: 0;
  width: 50px;
  height: 4px;
  content: "";
  background: var(--main-color);
}

.about-short .text{
  margin-bottom: 2rem;
  width: 90%;
  opacity: .7;
  font-size: 1.05rem;
}

.about-short .input-container{
 display: flex;
}

.about-short .input-container input{
  border-radius: 0.2rem;
  /*border:1px solid #8A8A8A;*/
  padding: 0 2.4rem;
  width: 300px;
  height: 50px;
  outline: none;
   box-shadow: 0 10px 25px rgba(0,0,0,.15);
    background-image: url(../../../assets/images/email-icon.svg);
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: 10px center;
}
.about-short .input-container button{
  padding: 0 2rem;
  border-radius: 0.2rem;
  background: var(--main-color);
  height: 50px;
  /*margin-left: 0.6rem;*/
  color: #fff;
  box-shadow: 0 10px 25px rgb(70 126 247 / 59%);
}


.top-features{
   padding: 2rem;
   /*background: #F5F5F5;*/
  background-position: center;
  background-size: cover;
  padding-top: 6rem;
}


 .section-title{
  width: 100%;
  text-align: center;
  padding: 0 2rem;

}
 .section-title h3{
   font-size: 1.8rem;
   margin-top: 0.3rem;
   font-weight: 600;
   text-transform: uppercase;
 

} 


 /*.top-features .section-title span{
  text-transform: uppercase;
  color: var(--main-color);
  font-weight: 500;
  font-size: 1.9rem;
 }*/

 .section-title span{
  font-size: 1.3rem;
  font-weight: 400;
}

.section-title label{
   color: var(--main-color);
}
.top-features  .items {
  display: flex;
  justify-content: center;
  max-width: 920px;
  margin: 4rem auto;
  align-items: initial;
}
.top-features  .items .item{
  /*border:1px solid #CAC8C8;*/
  border-radius: 0.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: initial;
  margin-top: 1.3rem;
  margin:0 0.5rem;
  /*background: #fff;*/
  width: 33%;
  background: #fff;
  box-shadow: 0 15px 45px rgba(16,30,54,.15);
  position: relative;
}






.top-features  .items .item .title{
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
}
.top-features  .items .item .icon{
   width: 60px;
   height: 60px;
   border-radius: 0.4rem;
   display: flex;
   justify-content: center;
   background: /*#467ef7cc var(--main-color)*/ transparent;
   margin-bottom: 0.6rem;
   align-items: center;
   border: solid var(--main-color);
}
.top-features  .items .item svg{
  fill: var(--main-color) !important;
}

.top-features  .items .item .text{
  opacity: .8;
}


.features-items{
    background: linear-gradient(180deg, #f8f8fc -2.31%, rgba(248, 248, 252, 0) 71.14%);
}
.features-items .item{
  display: flex;
  padding: 4rem;
  /*max-width: 1200px;*/
  overflow: hidden;
  margin: 0 auto;
  align-items: initial;

}
.features-items .item .text-container{
       display: flex;
       flex-direction: column;
       align-items: initial;
       width: 50%;
       margin-right: 2rem;
}

.features-items .item  label{
   margin-bottom: 2rem;
   font-size: 1.2rem;

}
.features-items .item.dashboard .text-container .text{
   opacity: .8;
}

.features-items .item .text-container button{
  padding: 0 2rem;
  border-radius: 0.2rem;
  background: var(--main-color);
  height: 50px;
  color: #fff;
  margin-top: 2.5rem;
  display: table;
  display: none;
}

.features-items .item.dashboard .text-container  button{
  background: #F9CE65;
}
.features-items .item.contracts .text-container  button{
  background: #4579E2;
}
.features-items .item.cash .text-container  button{
  background: #FFAC92;
}


.features-items .item .text-container .title{
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  color: rgb(41, 45, 52);
  margin-bottom: 4rem;
  position: relative;
  display: flex;
}
.features-items .item .text-container .title:after{
  position: absolute;
  bottom: -2rem;
  left: 0;
  width: 60px;
  height: 6px;
  content: "";
  border-radius: 0.1rem;
}
.features-items .item.dashboard .text-container .title:after{
  background: #F9CE65;
}
.features-items .item.contracts .text-container .title:after{
  background: #4579E2;
}
.features-items .item.cash .text-container .title:after{
  background: #FFAC92;
}
.features-items .item.contracts  .text-container{
  margin-left: 3rem;
}

.features-items .item  label{
  text-transform: uppercase;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  font-weight: 500;
}
.features-items .item.dashboard{
  height:  initial !important;
 }
.features-items .item.dashboard  label{
 color: #F9CE65;
}
.features-items .item.contracts  label{
 color: #4579E2;
}
.features-items .item.cash label{
 color: #FFAC92;
}
.features-items .item .image-container{
  position: relative;
  width: 70%;
}
.features-items .item .image-container.mobile-view{
  display: none;
}
.features-items .item .image-container:after{
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  right: 0;
  transform: scale(1.11);

}
.features-items .item .image-container:before{
  position: absolute;
  width: 90%;
  height: 140%;
  content: "";
  top: 0;
  background: url(../../../assets/images/bg-dots.png) left top / contain no-repeat;
  left: -5%;

}


.features-items .item.dashboard .image-container:after{
   background: url(../../../assets/images/dashboard-feature-bg.svg) left top / contain no-repeat;
   right:-60%;
   top: 10%;
}

.features-items .item.contracts .image-container:before{
   left: initial;
   right: -5%;
}

.features-items .item.contracts .image-container:after{
   background: url(../../../assets/images/contracts-feature-bg.svg) left top / contain no-repeat;
   left:-20%;
   top: 10%;
}
.features-items .item.cash .image-container:after{
   background: url(../../../assets/images/cash-feature-bg.svg) left top / contain no-repeat;
   right:-60%;
   top: 10%;
}
.features-items .item .image-container img{
  z-index: 1;
  position: relative;
}

.plans{
width: 100%;
margin: 4rem 0;
background: linear-gradient(180deg, #f8f8fc -2.31%, rgba(248, 248, 252, 0) 71.14%);
padding-top: 6rem;
margin-bottom:10rem;
}

.plans .items{
   display: flex;
   justify-content: center;
   align-items: initial;
    max-width: 1550px;
   margin:0 auto;
   margin-top: 4rem;
   padding: 1rem;
}

 



.plans .items .item{
  margin:0.4rem;
  width: 33%;
  /*-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);*/
  padding: 2rem;
  border-radius: 1rem;
  min-height: 300px;
 /* border:1px solid rgba(0,0,0,0.1);*/
 display: flex;
 align-items: initial;
 flex-direction: column;
 box-shadow: 0 15px 45px rgba(16,30,54,.15);
}
.plans .items .item.standard{
  background: var(--main-color);
  /*background-image: linear-gradient(135deg, #0919D7 0%, #4372D9 100%);*/
  background: /*#3166d8*/ #4d64e0;
  color: #fff;
}
.plans .items .item.standard *{
  color: #fff;
}

.plans .items .item .name{
  display: flex;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0,0,0,.3);
  padding: 1rem;;
}

.plans .items .item.standard .name{
  color: #fff;
}
.plans .items .item.standard .name{
   border-bottom: 1px solid rgba(255,255,255,.4);
}

.plans .items .item .price{
  display: flex;
  margin: 1rem 0;
  align-items: flex-end;
  justify-content: center;
}


.plans .items .item  .discount{
   width: 95%;
   font-size: 0.9rem;
   padding-bottom: 1rem;
   opacity: .9;
   position: relative;
   position: relative;
   text-align: center;
}

.plans .items .item  .discount:after{
   position: absolute;
  bottom:0;
  left: 50%;
  width: 13px;
  height:2px;
  content: "";
  background: rgba(0,0,0,0.5);
  transform: translateX(-50%);
}

.plans .items .item.standard  .discount:after{
  background: #ffffff82;
}

.plans .items .item .price .value{
  font-size: 2.3rem;
}
.plans .items .item.standard .price .value{
  color: #fff;
}

.plans .items .item .price label.per{
  font-size: 1rem;
  position: relative;
  display: table;
  transform: translateY(-0.5rem);
}


.plans .items .item .features{
   margin-top: 0.5rem;
}
.plans .items .item .features svg{
  margin-right: 0.4rem;
  fill: var(--main-color) !important;
  flex-shrink:0;
}

.plans .items .item.standard .features svg{
   fill: #fff !important;
}
   
.plans .items .item .features div{
  display: flex;
  margin-bottom: 0.5rem;

}  

.plans .items .item button{
  width: 100%;
  padding: 0.7rem 0rem;
  border: 1px solid var(--main-color);
  border-radius: 3rem;
  margin-top: 3rem;
  box-shadow: 0 10px 25px rgb(70 126 247 / 59%);
  background: #4d64e0;
  color: #fff;
} 

.plans .items .item  .btn-div{
  flex: 1;
  display: flex;
  align-items: flex-end;

}

.plans .items .item.standard  button{
  background: #fff;
  color: var(--main-color);
  border:1px solid transparent;
}

.plans .items .item  button:hover{
   background: transparent;
   color: var(--main-color);
   cursor: pointer;
   box-shadow: 0 10px 25px transparent;
}

.plans .items .item.standard button:hover{
   background: transparent;
   color: #ffff;
   cursor: pointer;
   border: 1px solid #fff;

}

.faq  {
  padding-top: 6rem;
  margin-top: 4rem;
}

.q-container {
  max-width:  800px;
  margin:  0 auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding: 0 1.5rem;
}

.q-container .item{
  width: 100%;
  display: flex;
  flex-direction: column;

}


.q-container .item .top{
  width: 100%;
  border-top: 1px solid rgba(0,0,0,0.3);
  display: flex;
  padding: 1.2rem 1rem;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 600;
}

.q-container .item.active .top{
   background: linear-gradient(180deg, #f8f8fc, rgba(248, 248, 252, .9) 71.14%);
   background: #f3f3fa;
   margin-bottom: 0.6rem;
   border-top: 0;
}


.q-container .item .content{
   padding: 0 1rem;
   border-radius: 0.3rem;
   opacity: 0; 
   transition: height 0.5s ease-in-out, opacity 0.3s ease-in-out; 
   overflow: hidden; 
   height: 0; 
}
.q-container .item.active .content{
  opacity: .8;
  height: auto; 
  padding: 0 1rem;
  padding-bottom: 3rem;
}

.q-container .item .top .title{
  font-size: 1.1rem;
}
.q-container .item svg{
  cursor: pointer;
  transition: 0.3;
}

.q-container .item.active svg{
  transform: rotate(180deg);
}
.q-container .item  svg:hover{
  opacity: .5;
}


footer{
  background: linear-gradient(180deg, #f8f8fc -2.31%, rgba(248, 248, 252, 0) 71.14%);
}
footer .footer{
  width: 100%;
  display: flex;
  padding: 2rem 1rem;
  border-top: 1px solid #ceceea;
   border-bottom:  1px solid #ceceea;
   justify-content: space-between;
   align-items: initial;

}

footer .footer .section{
  margin: 0 2rem;
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: initial;
}


footer .footer .section._start-end{
  justify-content: flex-end;
}
footer .footer .section .title{
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
}
footer .footer .section .text{
  opacity: .7;
  margin-bottom: 0.5rem;
}
footer .footer .section .links a{
    display: flex;
    margin-top: 0.5rem;
}
footer .footer .section .links a:hover{
  text-decoration: underline;
  cursor: pointer;
}
footer .footer .section .links a svg{
  opacity: .8;
  margin-right: 0.3rem;
}

footer .footer .section .input-div{
  width: 100%;
  display: flex;
  margin-top: 0.6rem;
}

footer .footer .section .input-div input{
  border-radius: 0.3rem;
  border:1px solid rgba(0,0,0,0.3);
  height: 40px;
  width: 100%;
  margin-right: 0.6rem;
  background: #fff;
  outline: none;
  padding: 0 2.5rem;
    background-image: url(../../../assets/images/email-icon.svg);
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: 10px center;
}


footer .footer .section .input-div button{
   padding: 0 2rem;
  border-radius: 0.3rem;
  background: var(--main-color);
  height: 42px;
  margin-left: 0.2rem;
  color: #fff;
}
footer .footer .section .input-div button svg{
  fill: #fff;
}

footer .copy{
   text-align: center;
   padding: 1rem;
} 


.join{
  width: 100%;
  background: url(../../../assets/images/home-bg.svg);
  background-position: center;
  background-size: cover;
  padding: 6rem 0;
  padding-top: 6rem;
  margin-top: 10rem;
}
.join *{
  color: #fff;
}
.join.done *{
   pointer-events: none !important;
}

.join-c{
  display: flex;
  max-width: 900px;
  margin:0 auto;
  justify-content: space-around;
  margin-top: 5rem;
  align-items: initial;
}

.join .right{
  width: 520px;
  overflow: hidden;
}
.join .right .slide > div{
  width: 520px;
  margin-right: 1rem;
}
.join .right .slide .plan{
  display: none;
}

.join .right .slide{
   width: 300%;
   display: flex;
   align-items: initial;
   right: 0;
   transition: 0.3s ease-in-out;
   align-items: flex-start;
}



.join.company .right .slide{
   margin-left: 0%;

}
.join.personal .right .slide{
   margin-left: -100%;
}
.join.confirm .right .slide{
  margin-left: -200%;
}

.join .right > div.active{
  display: block;
}

.join-c .steps{
  display: flex;
  flex-direction: column;
  align-items: initial;
  position: relative;
  
}
.join-c .steps span{
  padding: 1.5rem;
  opacity: .7;
  display: flex;
  position: relative;
  height: 40px;
  align-items: center;
}
.join-c .steps span.active{
  opacity: 1;
  font-weight: 600;
}

.join-c .steps:after{
  position: absolute;
  bottom:0;
  left: 0;
  width: 4px;
   height:100%;
  content: "";
  background: #ffffff82;
}
.join .join-c .steps:before{
  position: absolute;
  top:0;
  left: 0;
  width: 4px;
  height:50%;
  content: "";
  background:#fff;
  z-index: 1;
  transition: 0.1s ease;
}
.join.company .join-c .steps:before{
   top:0;
}
.join.personal .join-c .steps:before{
   top:50%;
}

.join ._plans{
   display: flex;
   width: 520px;
   justify-content: center;
   flex-wrap: wrap;
}
.join .plan .title{
  justify-content: center;
  display: flex;
  margin-bottom: 1rem;
  width: 520px;
}
.join ._plans .item{
    background: #fff;
    border-radius: 0.3rem;
    color: #000;
    margin: 0.6rem;
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.join ._plans  .options{
  width: 100%;
  text-align: center;
  margin-top: 2rem;

}
.join ._plans  .options.hide{
  opacity: 0;
  pointer-events: none;

}
.join.confirm .right > div:not(.confirm_container){
 /* display:none;*/
}

.join.confirm .confirm_container{
  display:flex;
  flex-direction: column;
  align-items: initial;
}

.join ._plans  .options button,
.join .confirm_container  button
{
   padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  border: 0;
  font-size: 1rem;
  height: 40px;
  color: #fff;
}
.join .confirm_container  .title{
  margin-bottom:  2rem;
}
.join.confirm .confirm_container .inputs{
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.join.confirm .confirm_container button{
  background: var(--main-color);
}
.join.confirm .confirm_container input{
  width: 190px !important;
  display: block; 
  color: #000;
  margin-bottom: 0.6rem;
}
.join.confirm .confirm_container .options span{
  text-decoration: underline;
  font-size: 0.9rem;
  cursor: pointer;
}
.join ._plans  .options button span {
  display: flex;
  align-items: center;
}
.join ._plans  .options button span svg{
  fill: #fff;
  margin-left:0.4rem;
}
.join ._plans label{
  display: flex;
}
.join ._plans label input{
  margin-right: 0.3rem;
  align-items: center;
}
.join ._plans .item:hover{
   border:2px solid  rgba(0,0,0,0.3);
}


.join ._plans .item.active{
   border:2px solid var(--main-color);
}
.join ._plans .item.active{
  color: #fff;
  border:2px solid var(--main-color);
  background: var(--main-color);
}



.landing-page .delete-dialog{
  position: fixed;
  top: 55%;
  transform: translate(-50%,-50%);
  left: 50%;
  z-index: 5;
  background: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 400px;
  box-shadow: 0 15px 45px rgba(16,30,54,.15);
  transition: 0.1s ease;
  opacity: 0;
  pointer-events: none;
}

.delete-dialog a{
  text-decoration: underline;
}
.delete-dialog button{
  color: #fff;

}

.delete-dialog.active{
  opacity: 1;
  top: 50%;
  pointer-events: visible;
}

.delete-dialog.active .alert-icon svg{
   animation: dialog_svg 0.4s forwards;
}


@keyframes dialog_svg {
  0% {
   transform: scale(1);
  }
  10% {
   transform: scale(0.4);
  }
  100% {
    transform: scale(1);
  }
}

.landing-page .create-microcredit{
  top: 4rem;
  z-index: 1;
  background: #fff;
  border-radius: 0.9rem;
  padding: 1rem;
  width: 520px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: 0.3s;
  color: #000;
  opacity: 1 !important; 
  overflow:visible !important;
  position: initial !important;
  transform: translate(0,0) !important;

}

.create-microcredit *{
   color: #000;
}

.create-microcredit.select-c{
  width: 320px;
}

.create-microcredit.select-c .c-options{
   display: flex;
   flex-direction: column;
   margin-top: 1rem;
}


.create-microcredit.select-c .c-options .o{
  padding: 0.5rem 0.3rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
  border-radius: 0.3rem;
  min-height: 50px;
  position: relative;

}
.create-microcredit.select-c .c-options .o .micro{
  font-size: 1.2rem;
}
.create-microcredit.select-c .c-options .o:hover{
  background: #ebebeb;
}

.create-microcredit.select-c .c-options .o:last-child{
  border-bottom: 0;
}
.create-microcredit.select-c .c-options .o .name{
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  opacity: .7;
}
.create-microcredit.select-c .c-options .o .role{
  font-size: 0.9rem;
  opacity: .7;
  position: absolute;
  right: 0.4rem;
  top: 0.5rem;
}
.delete-dialog p{
  padding: 1.5rem 1.3rem;
  font-size: 1.1rem;
  text-align: center;
}

.create-microcredit .top-title
{
  padding: 0.4rem;
  border-bottom: 1px solid #ebebeb;
  color: #000;
}
.delete-dialog .alert-icon{
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;

}



.create-microcredit .status{
  position: absolute;
  top: 0.5rem;
  right: 4rem;
  cursor: pointer;
  border-radius: 3rem;
  background: #ebebeb;
  padding: 0.5rem 0.7rem;
  font-size: 0.8rem;
}

.create-microcredit .btn-close,
.delete-dialog .btn-close
{
  position: absolute;
  top: 1rem;
  right: 1rem;
  opacity: .4;
  cursor: pointer;
}
.create-microcredit .btn-close:hover,
.delete-dialog .btn-close:hover
{
  opacity: .4;
}
.create-microcredit .input-container{
 margin-top: 2rem;
 display: flex;
 justify-content: space-between;
 flex-wrap: wrap;
 min-height: 130px;

}
.create-microcredit .res{
   display: flex;
   margin-bottom: 0.2rem;
   height: 30px;
   align-items: center;
}
.create-microcredit .res svg{
  margin-right: 0.2rem;
}
.input-container .inputs {
  position: relative;
}
.input-container .inputs .btn-add{
  position: absolute;
  right: 0;
  top: 0.2rem;
  /*background:#F3F3F3;*/
  padding: 0.05rem 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.input-container .inputs .btn-add svg{
  opacity: .3;
}
.input-container .inputs .btn-add:hover svg{
  opacity: .5;
}

.create-microcredit .input-container .inputs{
    display: flex;
    flex-direction: column;
    width: 48.5%;
    margin-bottom: 1.5rem;
    position: relative;
    align-items: initial;
}
.create-microcredit .input-container .inputs.full{
   width: 100%;
}


.loan-container .main-section .input-container .inputs._full{
  width: 98%;
}

@media screen and (min-width:800px){
  .create-microcredit.client{
    width: 700px;
  }
  .create-microcredit.client .input-container .inputs{
    width: 32%;
  }


} 


.create-microcredit .input-container .inputs .set-empty{
  position: absolute;
  right: 0;
  top: 0.2rem;
  opacity: 0;
  cursor: pointer;
}

.create-microcredit .input-container .inputs:hover .set-empty{
  opacity: .3;
}

.create-microcredit .input-container .inputs.not-null:hover .set-empty,
.create-microcredit .input-container .inputs.not-null .set-empty
{
  opacity: 1;
}


.create-microcredit .input-container .inputs span{
  opacity: .8;
  color: #000;
  font-size: 0.9rem;
  margin-bottom: 0.4rem;
}
.create-microcredit .input-container .inputs span label{
  color: crimson;
}
.create-microcredit .input-container .inputs div.label{
  display: flex;
  justify-content: space-between;
}
.create-microcredit .input-container .inputs div.label label{
  display: flex;
  align-items: center;
  opacity: .7;
  font-size: 0.9rem;
  cursor: pointer;
}
.create-microcredit .input-container .inputs div.label label input{
  margin-left: 0.4rem;
  border: 0;
  cursor: pointer;
}
.inputs select{
  font-size: 0.95rem;
}
.create-microcredit .input-container .inputs input,
.create-microcredit .input-container .inputs select,
.create-microcredit .input-container .inputs textarea,
.loan-container .main-section .input-container .inputs select,
.loan-container .main-section .input-container .inputs input,
.join.confirm .confirm_container input,
.loan-container .main-section .input-container .inputs textarea
{
  border: 1px solid #D9D9D9;
  border-radius: 0.2rem;
  padding:0.4rem 0.3rem;
  width: 100%;
  /*background: #FCFCFC;*/
  height: 38px;
   -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  outline: none;
  color: #000 !important;
}


.create-microcredit .input-container input[type="checkbox"]{
  width: 15px;
  height: 15px;
}

.join.confirm .confirm_container input, .create-microcredit .input-container .inputs:not(.search-select) *:focus, .loan-container .main-section .input-container .inputs:not(.search-select) *:focus{
  box-shadow: 0 0 4px #2360e48a;
  border: 1px solid #2360e4a6;
}

.create-microcredit .input-container input::placeholder{
  font-size: 0.8rem;
  opacity: .8;
}

.create-microcredit  .options
{
  border-top:  1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}
.delete-dialog .options
{
    border-top: 1px solid #ebebeb;
    padding-top: 0.6rem;
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
}
.delete-dialog .options{
    border-top:  0;
}

.create-microcredit .options button,
.delete-dialog .options button
{
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  border: 0;
  font-size: 1rem;
  height: 40px;
}
.delete-dialog .options button{
  margin:  0 1.6rem;
  padding: 0.5rem 2rem;
}

.create-microcredit .options button.cancel,
.delete-dialog .options button.cancel
{
    background:#f8f9fa;
    color: #8a8a8a !important;
}

.create-microcredit .options button.cancel span{
  color: #8a8a8a !important;
}


.delete-dialog .options button.save,
.create-microcredit .options button.save
{
   background: #2360E4;
}
.create-microcredit .options button.save span{
    color: #fff;
}
.options button.save:hover,
.options button.cancel:hover
{
  opacity: .9;
}
.div_btn{
   position: relative;
   display: table;
}

.div_btn .loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 25px;
    height: 25px;
    border: 2px solid #ddd;
    border-top:  2px solid transparent;
    border-left:  2px solid transparent;
    border-radius: 50%;
    float: right;
    animation: btn_loader 0.6s linear infinite;
    opacity: 0;
    pointer-events: none;

}

.create-microcredit.loading  .div_btn .loader,
.confirm_container.loading  .div_btn .loader,
.newsletter.loading  .div_btn .loader
{
   opacity: 1;
   pointer-events:visible;
}
.newsletter.loading  .div_btn svg{
  opacity: 0;
}

.newsletter.loading  .div_btn *{
  pointer-events: none !important;
}
.confirm_container.loading  .options{
  opacity: 0;
  pointer-events: none;
}
.create-microcredit.loading  button,
.confirm_container.loading .div_btn button
{
  pointer-events: none;
}
.create-microcredit.loading  button.cancel
{
   opacity: 0 !important;
   pointer-events: none;
}

.create-microcredit.loading span,
.confirm_container.loading .div_btn span
{
   opacity: 0;
   pointer-events: none;
}

@keyframes btn_loader{
  from{
         transform:translate(-50%,-50%) rotate(-360deg);
  }to{
         transform:translate(-50%,-50%) rotate(0deg);
  }
}



._container .main-section{
  width: 100%;
  height:200px;
  background-color: #0919D7;
  background-image: linear-gradient(135deg, #0919D7 0%, #4372D9 100%);
  background: url(../../../assets/images/main-bg.svg);
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 2.5rem;

}

.main-section .title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  width: 100%;

}


.divide{
  display: flex;
}
.divide .right{
  padding: 2rem;
  max-width: 1200px;
}

.divide .right .section-item h2{
  font-size: 1.4rem;
  margin-top: 2rem;
  margin-bottom: 0.8rem;
}
.divide .right .section-item p{
  color: #374151;
  margin-bottom: 1rem;
}
.divide .right .section-item a{
  text-decoration: underline;
}
.divide ul,.divide ol{
  list-style: initial;
  list-style-type: initial;
  padding-left: 2rem;
  margin:1.2rem 0;

}
.divide ul li{
  margin-bottom: 0.8rem;
}

.divide strong{
  opacity: 0.7;
}

.full-text{
  padding:4rem 2rem;

}



@media screen and (max-width:1024px){
   ._menu h1 .long{
       display: none;
   } 
   ._menu h1 .short{
       display: block;
   }  

   ._home-content ._left-home h3{
     font-size: 1.4rem;
   }
   ._home-content ._left-home{
    width: 55%;
    padding-left: 50px;
   }

   .top-features .items {
     flex-wrap: wrap;
     justify-content: flex-start;
   }

    .top-features .items .item{
        width: calc(50% - 1rem);
        margin-bottom: 1rem;

    }

     .features-items .item .text-container .title{
      font-size: 2rem;
      margin-bottom: 1.2rem;
    }
    .features-items .item .text-container .title:after{
      display: none;
    }
    .features-items .item .image-container:after{
      display: none;
    }
    .features-items .item {
      padding:2rem;
    }
    .features-items .item .image-container{
      width: 70%;
    }

     footer .footer{
      flex-wrap: wrap;
     }

    footer .footer .section{
      margin: 0 2rem;
      width: calc(50% - 4rem);
      margin-bottom: 3.5rem;

    }

    .about-short .right{
      width: 100%;
      position: relative;
      margin-top: 2rem;
    }

    .about-short .right:after {
    width: 100%;
    height: 100%;
    transform: scale(0.9);
    background: url(../../../assets/images/short-about-bg.svg) right top / contain no-repeat;
    right: -20%;
    top: -10%;
 } 

   .about-short{
    padding-left: 3rem;
  }

  

} 


@media screen and (max-width:1200px){
  .plans .items{
    /*flex-direction: column;*/
    flex-wrap: wrap;
    padding: 0 3rem;
  }
  .plans .items .item{
    width: calc(50% - 1rem);
    margin: 0 0.5rem;
    margin-bottom: 3rem;
    
  }
}


@media screen and (max-width:940px){
  .about-short{
     flex-direction: column;
    }
   
}

@media screen and (max-width:800px){
  ._home-content{
     flex-direction: column;
     height: auto;
     padding-bottom: 2rem;
   }

   ._home-content ._left-home{
     width: 100%;
     margin-top: 2rem;
   }

   ._home-content ._right-home {
     flex: 1;
     position: relative;
  }
  ._home-content ._right-home img{
     width: 100%;
     max-width: 90%;
     margin: auto;
  }
  ._home-content ._left-home h3{
     font-size: 1.6rem;
   }

   ._home-content ._left-home span{
     max-width: 100%;
   }
   ._home-content ._left-home ._btns {
     margin-top: 2rem;
   }

   .features-items .item{
     flex-direction: column;
     margin-bottom: 2rem;
   }

   .features-items .item .text-container{
    width: 100%;
    
   }

   .features-items .item .text-container .text{
    margin-top: 1rem;
    padding: 0 0.5rem;
   }
   .features-items .item .image-container{
      width: 100%;
    }
     .features-items .item .image-container.desktop-view{
        display: none;
    }
    .features-items .item .image-container.mobile-view{
        display: block;
    }
    .features-items .item.contracts .text-container{
      margin-left: 0;
    }
    .features-items .item.contracts .image-container:before{
      left: -5%;
    }

    .plans .items{
      flex-direction: column;
      padding: 0 3rem;
    }
    .plans .items .item{
      width: 100%;
      margin-bottom: 3rem;
    }
    .join .left{
      display: none;
    }

    .join .right .slide > div{

    }
    .join-c{
      padding: 1rem;
    }
    .section-title span{
      font-size: 1rem;
    }
   

     footer .footer .section.full-in-mobile{
        width: 70%;
    }

   
    .faq,.join{
      margin-top: 0 !important;
    }
    .plans{
      margin-bottom: 2rem;
      padding-top: 4rem;
    }
    .top-features{
      padding-top: 4rem;
    }


} 


@media screen and (max-width:700px){
   .top-features .items .item{
        width: 100%;
        margin-bottom: 1rem;
    }

     .create-microcredit .input-container .inputs {
      width: 100%;
  }

   footer .footer .section.full-in-mobile{
        width: 100%;
    }

    footer .footer .section{
        width: 100%;
    }

    .plans .items{
      padding: 0 1rem;
    }



._container ._menu{
  padding: 1rem 1rem;
}

._menu ._left ._links .bg-close{
  position: absolute;
  width: 50%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  right: 0;
  transform: translateX(100%);
  opacity: 0;
  pointer-events: none;
}

._menu ._left ._links{
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  padding: 1rem;
  width: 70%;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  transform: translateX(-100%);
  transition: 0.2s ease-in-out;
}
._menu ._left ._links.active{
  transform: translateX(0%);
}

._menu ._left ._links.active .bg-close{
   animation: bg-menu-mobile 0.3s forwards;
   pointer-events: visible;
}

@keyframes bg-menu-mobile {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


._menu ._left ._links .btn-close-menu{
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  display: flex;
}

._menu ._left ._links .btn-close-menu svg{
  fill: #000;
}
._menu .burger{
  margin-left: 0.7rem;
  cursor: pointer;
  display: block;
}

._menu .burger svg{
  fill: #fff;
}
._menu.float .burger svg,
._container._white_menu ._menu .burger svg
{
  fill: #000;
}

._menu ._left ._links ul{
  display: flex;
  flex-direction: column;
  align-items: initial;
  height: 100vh;
  padding-top: 2rem;
}
._menu ._left ._links ul li a{
 font-size: 1.1rem;
}
._menu ._left ._links ul li{
 margin-bottom: 1rem;
}

._menu ._left ._links li a,
._container._white_menu ._menu li a
{
   color: #000 !important;
}






}



@media screen and (max-width: 600px) {

   ._home-content ._left-home ._btns button{
        width: 100%;
        margin-bottom: 1rem;
   }

}
  