/*******dasboard center********/
.dashboard .main-dashboard .center{
  width: 100%;
  margin-top: 1rem;


}
.dashboard .main-dashboard .center .stat_page_1{
  /*width: 100%; */
  display: flex;
}

.dashboard .main-dashboard .center .stat_page_1 .left-side{
   display: flex;
   flex: 1;

}
.dashboard .main-dashboard .center .stat_page_1 .left-side .items{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.dashboard .main-dashboard .center .stat_page_1 .left-side .items .box{
    border: 1px solid var(--border-color);
    background: var(--box-color);
    border-radius: 0.6rem;
    padding: 0.8rem;
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 48%;
    margin-right: 2%;
    position: relative;
    margin-bottom: 0.5rem;
    overflow: hidden;

}

.dashboard .main-dashboard .center .stat_page_1 .left-side .items .box .name{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 400;
  opacity: .8;
}


.dashboard .main-dashboard .center .stat_page_1 .left-side .items .box .total{
  font-weight: 600;
  font-size: 1.1rem;
}
.dashboard .main-dashboard .center .stat_page_1 .left-side .items .box .name .icon{
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-shrink: 0;
}
.dashboard .main-dashboard .center .stat_page_1 .left-side .items .box .total-text{
   opacity: .7;
}


.dashboard .main-dashboard .center .stat_page_1 .right-side{
  width: 55%;
  padding-bottom: 0.5rem;


}

.dashboard .main-dashboard .center .stat_page_1 .right-side .chart{
  width: 100%;
  height: 100%;
}
.dashboard .main-dashboard .center .stat_page_1 .right-side .chart-c{
  background: #fff;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
}

.dashboard .main-dashboard .center .stat_page_1 .right-side .chart-c .box-top-title{
  padding: 0.5rem 1rem;
}


.dashboard .main-dashboard  .stat_page_2{
  width: 100%;
  display: flex;
  margin-top: 0.5rem;
  overflow: auto;
}
.dashboard .main-dashboard  .stat_page_2::-webkit-scrollbar{
  display: none;
}

.dashboard .main-dashboard   .stat_page_2 .items{
   width: 100%;
   display: flex;
}


.dashboard .main-dashboard   .stat_page_2 .box{
  display: flex;
  align-items: center;
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
  width: 100%;
  margin-right: 0.7rem;
  overflow: hidden;
  position: relative;

}
.dashboard .main-dashboard   .stat_page_2 .box:hover{
 /* border: 1px solid #2360E4;*/
}
.dashboard .main-dashboard   .stat_page_2 .box:last-child{
   margin-right: 0;
}


.dashboard .main-dashboard  .stat_page_2 .box .icon{
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 1rem;
}

.dashboard .main-dashboard  .stat_page_2 .box  .center-content{
   flex: 1;
}
.dashboard .main-dashboard  .stat_page_2 .box  .center-content .name{
  opacity: .7;
}
.dashboard .main-dashboard  .stat_page_2 .box .add-btn{
  height: 100%;
  width: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.dashboard .main-dashboard  .stat_page_2 .box .add-btn:hover{
   cursor: pointer;
   opacity: .5;
}

 

.dashboard .main-dashboard  .stat_page_2 .box .total{
  font-weight: 500;
  font-size: 1.05rem;

}

.dashboard .main-dashboard  .stat_page_3{
  display: flex;
  margin-top: 1rem;

}
.dashboard .main-dashboard  .stat_page_3 .chart-container{
  width: 55%;
  background: #fff;
  border-radius: 0.3rem;
  padding: 1rem;
}
.dashboard .main-dashboard .revenue-chart{
  background: #fff;
  border-radius: 0.3rem;
  padding: 1rem;
  margin-top: 1rem;
}
.dashboard .main-dashboard  .stat_page_3 .chart-container .chart{
  width: 100%;
  height: 100%;
}
.dashboard .main-dashboard  .stat_page_3 .loans-status{
 flex: 1;
 background: #fff;
 border-radius: 0.3rem;
 padding: 1rem 1.5rem;
 margin-left: 0.5rem;
 width: 45%;

}

.dashboard .main-dashboard  .stat_page_3 .loans-status .list .item{
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding: 1rem 0;
  justify-content: space-between;

}
.dashboard .main-dashboard  .stat_page_3 .loans-status .list .item:last-child{
   border-bottom: 0;
}

.dashboard .main-dashboard  .stat_page_3 .loans-status .list .item .name{
  opacity: .7;
}




.dashboard .main-dashboard  .box-top-title{
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 0.3rem 0;
  font-weight: 600;
  opacity: .8;
}




@media screen and (max-width:1250px){
     .dashboard .main-dashboard .center .stat_page_1 .left-side .items .box .name .icon,
     .dashboard .main-dashboard  .stat_page_2 .box .icon
     {
          width: 46px;
          height: 46px;
     }


} 
@media screen and (max-width:1150px){
    
     .dashboard .main-dashboard .center .stat_page_1,
     .dashboard .main-dashboard .stat_page_3 

     {
      flex-direction: column;

     }
     .dashboard .main-dashboard .stat_page_3{
      margin-top: 0;
     }

     .dashboard .main-dashboard .center .stat_page_1 .left-side .items .box:nth-child(2),
     .dashboard .main-dashboard .center .stat_page_1 .left-side .items .box:nth-child(4)
     {
        margin-right: 0;
     }

     .dashboard .main-dashboard .center .stat_page_1 .left-side .items .box
     {
        width: 49%;
     }
     .dashboard .main-dashboard .center .stat_page_1 .right-side,
     .dashboard .main-dashboard .center .stat_page_3 .chart-container
     {
        width: 100%;
        margin-top: 1rem;
     }
     .dashboard .main-dashboard .stat_page_3 .loans-status{
       margin-left: 0;
       margin-bottom: 1rem;
       margin-top: 0.6rem;
       width: 100%;
     }

} 




@media screen and (max-width:1024px){

     .dashboard .main-dashboard   .stat_page_2 .items{
        flex-wrap: wrap;
        justify-content: space-between;
     }
     .dashboard .main-dashboard   .stat_page_2 .items .box{
        width: 49%;
        margin-right:0;
        margin-bottom: 0.7rem;
     }

} 

@media screen and (max-width:600px){

     .dashboard .main-dashboard .center .stat_page_1 .left-side .items .box .name .icon,
     .dashboard .main-dashboard  .stat_page_2 .box .icon
     {
         display: none;
     }

     

     .dashboard .main-dashboard .center .stat_page_1 .left-side .items .box .name {
        margin-bottom: 1rem;
     } 

     .dashboard .main-dashboard .center .stat_page_1  .items .box:after,
     .dashboard .main-dashboard .center .stat_page_2  .items .box:after
     {
       position: absolute;
       left: 0;
       top: 0;
       content: "";
       height: 40%;
       width:2px;
       background: #2360e44d;
    }




} 










