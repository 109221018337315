.loan-container{
	width: 100%;
	height: 100%;
	background: #fff;
	border-radius: 0.5rem;
	min-height: 70vh;
	padding-bottom: 2rem;
}

.loan-container .top-title{
	width: 100%;
	padding: 1rem;
	border-bottom:1px solid var(--border-color);
}

.loan-container .sub-title{
	padding: 1rem;
}

.loan-container .sub-title button{
	border: 1px solid var(--main-color);
	color: var(--main-color);
	border-radius: 0.2rem;
	margin-left: 1rem;
	padding: 0.1rem 1rem;
}
.loan-container .sub-title button:hover{
	opacity: .7;
}
.loan-container .section{
	 border-bottom: 1px solid var(--border-color);
}

.loan-container .section:last-child{
    border-bottom: 0;
}

.loan-container ._table{
  padding: 1rem;
  width: 100%;
}

@media screen and (max-width:900px){
    .loan-container ._table{
      padding: 0.3rem;
    }
}

@media screen and (max-width:900px){
    .loan-container .sub-title span{
       width: 100%;
    }
    .loan-container .sub-title button{
       margin-left: 0;
       margin-top: 1rem;
    }
}
