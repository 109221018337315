/*******dasboard center********/
.dashboard .main-dashboard .center{
  width: 100%;
  margin-top: 1rem;
}

.dashboard .main-dashboard  .box-top-title{
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 0.3rem 0;
  font-weight: 600;
  opacity: .8;
}
