.table-container {
    overflow-x: auto; /* Allow horizontal scrolling */
    width: 100%;
    background-color: #fff;
    max-height: 280px;
  }

  .c-table .table-container{
    max-height: auto;
  }
  
  table {
    width: 100%; /* Table takes 100% of its container */
    border-collapse: collapse;
  }
  
  th, td {
    padding: 8px 15px;
    /*border: 1px solid #ddd;*/
    text-align: left;
    width: auto; /* By default, let content determine column width */
  }
  td{
      border-top:1px solid rgba(207, 205, 205, 0.356); /*#ddd;*/
      opacity: .8;
    font-weight: 400;
    font-size: 0.9rem;
  }
  

  
  /* Optional: Add styling for table header */
  th {
    /*background-color: #f2f2f2;*/
    color: #333;
    font-weight: 500;
    opacity: .9;
  
  }
  