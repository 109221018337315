.table-container {
  overflow-x: auto; /* Allow horizontal scrolling */
  width: 100%;
  max-height: 280px;
}
.table-container.last_transations td{
  font-size: 0.82rem;
}
table {
  width: 100%; /* Table takes 100% of its container */
  border-collapse: collapse;
}
th, td {
  padding: 8px 15px;
  /*border: 1px solid #ddd;*/
  text-align: left;
  width: auto; /* By default, let content determine column width */
}
td{
	border-top:1px solid rgba(207, 205, 205, 0.356); /*#ddd;*/
	opacity: .8;
  font-weight: 400;
  font-size: 0.9rem;
}
.tr:hover{
 background-color: #f2f2f2;
  border-radius: 0.2rem;
  cursor: pointer;
  padding: 0 0.7rem;
}
.table-container.last_transations .tr:hover{
   background: transparent;
   cursor: initial;
}
tr:active{
  opacity: .7;
}

/* Optional: Add styling for table header */
th {
  /*background-color: #f2f2f2;*/
  color: #333;
  font-weight: 500;
  opacity: .9;
}
