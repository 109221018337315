/* quill-custom-styles.css */

/* Custom styles for the editor container */
.my-quill-editor-container {
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
  position: relative;
  width: 70%;
}

@media (max-width: 1200px){
  
.my-quill-editor-container {
  width: 100%;
}


}
/* Custom styles for the editor toolbar */
.ql-toolbar {
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
}

/* Custom styles for the editor content */
.ql-editor {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
}
