.contract_template_editor{
	padding: 2rem;
	background: #fff;
	margin-top: 2rem;

}

.ql-editor {
    /* Styles for the editor container */
    font-family: Arial, sans-serif;
    border: 1px solid #ccc;
    padding: 0 10px;
    min-height: 800px;
}

.ql-toolbar {
    /* Styles for the toolbar */
    background-color: #fff;
    border-bottom: 1px solid #ccc;
}

/* ... styles for other Quill elements */


.contract-template-placeholders{
   flex: 1;
   padding: 1rem;
   background: #fff;
   border-radius: 0.3rem;
   border:1px solid rgba(0,0,0,0.1);
   margin-bottom: 1rem;
   margin-right: 1rem;
   word-break: break-all;
}

.contract-template-placeholders .title{
	width: 100%;
	display: flex;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	padding: 0.4rem;
}

.contract-template-placeholders .params{
	margin-top: 1rem;
	display: flex;
	flex-wrap: wrap;
}

.contract-template-placeholders .params div{
 margin-bottom: 1rem;
}

.contract-template-placeholders .params div .p{
	color: var(--main-color);
  opacity: .7;
	font-size: 0.9rem;
}
.contract-template-placeholders .params div .name{
  font-weight: 400;
  font-size: 0.9rem;
}

.template-container{
  display: flex;
  align-items: flex-start;
}

.float-bar{
  position: absolute;
  z-index: 1;
  right: 0.5rem;
  top: 0.5rem;
  padding: 0.4rem;
  background: #fff;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
}
.float-bar button{
  border-radius: 0.3rem;
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  margin: 0 0.1rem;
}
.float-bar button.edit{
  background: #fff;
  color:  var(--main-color);
  border:1px solid var(--border-color);
  text-decoration: underline;
}
.float-bar button.cancel{
  display: none;
  border:1px solid transparent;
  background:#f8f9fa;
  color: #8a8a8a;
}

.float-bar button.save{
  background: var(--main-color);
  color: #fff;
  display: none;
  border:1px solid transparent;
}

.my-quill-editor-container.editing .float-bar button.edit{
   display: none;
}
.my-quill-editor-container.editing .float-bar button.save{
   display: block;
}
.my-quill-editor-container.editing .float-bar button.cancel{
   display: block;
}

.float-bar{
  display: none;
}
.my-quill-editor-container.loaded .float-bar{
  display: flex;
}

.float-bar button:hover{
  opacity: .8;
}
.float-bar button:active{
  opacity: .5;
}
.my-quill-editor-container.saving .float-bar button.save{
   display: none;
}
.my-quill-editor-container.saving .float-bar button.cancel{
   display: none;

}

.ql-editor
{
  pointer-events: none;
  opacity: .6;
  border: 0 !important;
}
 .ql-toolbar
{
  border: 0 !important;
  opacity: .6;
  width: calc(100% - 140px);
  pointer-events: none;
}
.my-quill-editor-container.editing  .ql-toolbar,
.my-quill-editor-container.editing  .ql-editor
{
  pointer-events: visible;
  opacity: 1;
}
.my-quill-editor-container.saving  .ql-editor
{
  pointer-events: none;
  opacity: .6 !important;
}

.my-quill-editor-container.saving  .ql-toolbar
{
  opacity: .6 !important;
  pointer-events: none;
}

.my-quill-editor-container .loader{
  position: absolute;
  top: 105px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  padding: 0.4rem;
  background: #fff;
  border:1px solid var(--border-color);
  border-radius: 0.3rem;
  color: var(--main-color);

}
.my-quill-editor-container.loaded .loader{
  display: none;
}

@media (max-width: 1200px){
  
  .template-container{
    display: block;
  }
  .contract-template-placeholders .params div{
    width: calc(33.33% - 2rem);
    margin-right: 1rem;
  }
  .contract-template-placeholders{
   margin-right: 0;
 }


}

@media (max-width: 800px){

  .contract-template-placeholders .params div{
    width: calc(50% - 1rem);
     margin-right: 1rem;
  }
  .float-bar{
    position: absolute;
    z-index: 1;
    right: 0.5rem;
    top: 0.5rem;
    padding: 0.4rem;
    background: #fff;
    border-radius: 0.3rem;
    display: flex;
    justify-content: center;
  }


}

@media (max-width: 500px){

  .contract-template-placeholders .params div{
    width: 100%;
     margin-right: 0rem;
  }




}


